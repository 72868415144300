<template>
  <div class="content">
    <div class="order-detail">
      <div class="header">
        <div class="title">
          <i></i>
          <span>{{ $t('common.OrderDetail') }}</span>
        </div>
        <div class="right-option">
          <div class="option">
            <span>{{ $t('common.timeZone') }}&nbsp;:&nbsp;&nbsp;</span>
            <el-select
              v-model="timer"
              class="language-select"
              size="small"
              @change="changeTimeZone"
            >
              <el-option
                v-for="item in timeZone"
                :key="item.val"
                :label="item.name"
                :value="Number(item.val)"
              />
            </el-select>
          </div>
          <div class="option" style="margin-left: 10px">
            <span>{{ $t('common.Language') }}&nbsp;:&nbsp;&nbsp;</span>
            <el-select
              v-model="language"
              class="language-select"
              size="small"
              @change="handleSetLanguage"
            >
              <el-option
                v-for="item in languageList"
                :key="item.val"
                :label="item.name"
                :value="item.val"
              />
            </el-select>
          </div>
        </div>
      </div>
      <div class="card">
        <div>
          <p>{{ $t('common.OrderId') }}</p>
          <span class="label">{{ orderId }}</span>
        </div>
        <div>
          <p>{{ $t('common.FBUserID') }}</p>
          <span class="label">{{ fBUserID }}</span>
        </div>
        <div>
          <p>{{ $t('common.ChannelUserID') }}</p>
          <span class="label">{{ channelUserID }}</span>
        </div>
        <div>
          <p>{{ $t('common.OrderTime') }}</p>
          <span v-if="orderTime" class="label">{{ formatUtc(orderTime) }}</span>
        </div>
        <div>
          <p>{{ $t('common.Status') }}</p>
          <template>
            <span v-for="(item, index) in statusList" :key="index">
              <span v-if="status == item.id" class="label">
                {{ $t(`common.${item.label}`) }}
              </span>
            </span>
          </template>
        </div>
        <div>
          <p>{{ $t('common.Type') }}</p>
          <span v-if="seriesType == 1" class="label">
            {{ $t('common.parlay') }}&nbsp;({{ betType }})
          </span>
          <span v-else class="label">{{ $t('common.single') }}</span>
        </div>
        <div>
          <p>{{ $t('common.Currency') }}</p>
          <span class="label">{{ getCode }}</span>
        </div>
        <div>
          <p>{{ $t('common.mystake') }}</p>
          <span class="label">{{ liabilityStake }}</span>
          <span v-if="allUpBetRemark" style="color: #333">
            ({{ allUpBetRemark }})
          </span>
        </div>
        <div>
          <p>{{ $t('common.CompanyWinLoss') }}</p>
          <span class="label win" :class="{ loss: `${companyWinLoss}` < 0 }">
            {{ companyWinLoss }}
          </span>
        </div>
        <div>
          <p>{{ $t('common.IP') }}</p>
          <span class="label">{{ ip }}</span>
        </div>
      </div>
    </div>
    <div class="order-detail">
      <div class="header">
        <div class="title">
          <i></i>
          <span>{{ $t('common.BetDetail') }}</span>
        </div>
        <div class="option"></div>
      </div>
      <div class="bet-detail">
        <el-table v-loading="loading" border :data="list">
          <el-table-column :label="$t('common.Match')" width="300">
            <template v-slot="{ row }">
              <div
                v-for="(item, index) in row.options"
                :key="index"
                class="table-td-item match"
              >
                <p>
                  {{ getSportName(item.match.sportId) }}
                  <span v-if="item.match" class="matchId">
                    {{ item.match.id }}
                  </span>
                  <span v-if="item.match.startTime">
                    {{ formatUtc(item.match.startTime) }}
                  </span>
                </p>
                <p class="home-away">
                  {{ item.match.teams[0]?.name }}
                  <span v-if="item.match.teams[0] && item.match.teams[1]">
                    VS
                  </span>
                  {{ item.match.teams[1]?.name }}
                </p>
                <p>{{ item.match.leagueName }}</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('common.Seletion')" width="300">
            <template v-slot="{ row }">
              <div
                v-for="(item, index) in row.options"
                :key="index"
                class="table-td-item detail"
              >
                <div>
                  <p>
                    <span v-if="item.match.inPlay" class="text-red">
                      {{ $t('common.Live') }}
                    </span>
                    <span v-if="!item.match.inPlay" class="text-red">
                      {{ $t('common.PreMatch') }}
                    </span>
                    <span>{{ item.marketGroupName }}</span>
                    <span>
                      {{ item.marketName }}
                      <span v-if="item.extraInfo">({{ item.extraInfo }})</span>
                    </span>
                    <br />
                    <span>{{ item.betScore }}</span>
                  </p>
                  <div>
                    {{ item.name }}
                    <span v-if="seriesType == 1">
                      <span>
                        / @
                        <span
                          class="text-blue"
                          style="color: #409eff"
                          type="text"
                        >
                          {{ item.betOdds }}
                        </span>
                      </span>
                      <span v-if="item.oddsFormat == 1" class="euro">
                        {{ $t('common.EURO') }}
                      </span>
                      <span v-if="item.oddsFormat == 2" class="euro">
                        {{ $t('common.HK') }}
                      </span>
                      <span v-if="item.oddsFormat == 3" class="euro">
                        {{ $t('common.ML') }}
                      </span>
                      <span v-if="item.oddsFormat == 4" class="euro">
                        {{ $t('common.YN') }}
                      </span>
                      <span v-if="item.oddsFormat == 5" class="euro">
                        {{ $t('common.MN') }}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('common.Result')" width="230">
            <template v-slot="{ row }">
              <div
                v-for="(item, index) in row.options"
                :key="index"
                class="result"
              >
                <p v-if="item.settleResult == 7" class="result-text">
                  <span>·</span>
                  <span>{{ $t('common.Cancel') }}</span>
                </p>
                <p v-if="item.settleResult == 2" class="text-blue result-text">
                  <span>·</span>
                  <span>{{ $t('common.Push') }}</span>
                </p>
                <p v-if="item.settleResult == 3" class="text-green result-text">
                  <span>·</span>
                  <span>{{ $t('common.Loss') }}</span>
                </p>
                <p v-if="item.settleResult == 4" class="text-red result-text">
                  <span>·</span>
                  <span>{{ $t('common.Win') }}</span>
                </p>
                <p v-if="item.settleResult == 5" class="text-red result-text">
                  <span>·</span>
                  <span>{{ $t('common.WinReturn') }}</span>
                </p>
                <p v-if="item.settleResult == 6" class="text-green result-text">
                  <span>·</span>
                  <span>{{ $t('common.LossReturn') }}</span>
                </p>
                <p v-if="item.resultScore">
                  {{ $t('common.Result1') }} {{ item.resultScore }}
                </p>
                <p v-if="item.settleResult == 7">{{ item.cancelReason }}</p>
                <!-- <p>{{ allUpBetRemark }}</p> -->
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('common.Odds')" prop="odds">
            <template v-slot="{ row }">
              <div v-if="seriesType != 1">
                @
                <span class="text-blue" type="text">
                  {{ row.options[0].betOdds }}
                </span>
                <span v-if="row.options[0].oddsFormat == 1" class="euro">
                  {{ $t('common.EURO') }}
                </span>
                <span v-if="row.options[0].oddsFormat == 2" class="euro">
                  {{ $t('common.HK') }}
                </span>
                <span v-if="row.options[0].oddsFormat == 3" class="euro">
                  {{ $t('common.ML') }}
                </span>
                <span v-if="row.options[0].oddsFormat == 4" class="euro">
                  {{ $t('common.YN') }}
                </span>
                <span v-if="row.options[0].oddsFormat == 5" class="euro">
                  {{ $t('common.MN') }}
                </span>
              </div>
              <div v-if="seriesType == 1" class="odds">
                @ {{ odds(stake, maxWinAmount, betNum) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('common.kkstake')" prop="companyWinLoss">
            <template>
              <div>{{ stake }}</div>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('common.CompanyWinLoss')"
            prop="companyWinLoss"
          >
            <template>
              <div class="win" :class="{ loss: `${companyWinLoss}` < 0 }">
                {{ companyWinLoss }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="right"
            :label="$t('common.SettleStake')"
            prop="settleStake"
          >
            <template>
              <div>{{ settleStake }}</div>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('common.SettleReturn')"
            prop="settleReturn"
          >
            <template>
              <div>{{ settleReturn }}</div>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('common.CashoutStake')"
            prop="cashOutStake"
          >
            <template>
              <div>{{ cashOutStake }}</div>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('common.myCashoutStake')"
            prop="cashOutStake"
          >
            <template>
              <div>{{ liabilityCashoutStake }}</div>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('common.CashoutReturn')"
            prop="cashOutReturn"
          >
            <template>
              <div>{{ cashOutReturn }}</div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('common.SettleTime')" prop="settleTime">
            <template>
              <div v-if="settleTime">{{ formatUtc(settleTime) }}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="order-detail">
      <div class="header">
        <div class="title">
          <i></i>
          <span>{{ $t('common.CashoutDetail') }}</span>
        </div>
        <div class="option"></div>
      </div>
      <div>
        <p class="count">
          {{ $t('common.CashoutCount') }}&nbsp;:&nbsp;{{ cashOutCount || '0' }}
        </p>
        <el-table v-loading="loading" border :data="cashOuts">
          <el-table-column :label="$t('common.CashoutID')" prop="id" />
          <el-table-column :label="$t('common.CashoutTime')">
            <template v-slot="{ row }">
              <div v-if="row.createTime">{{ formatUtc(row.createTime) }}</div>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('common.CashoutStake')"
            prop="stakeAmount"
          />
          <el-table-column
            :label="$t('common.myCashoutStake')"
            prop="liabilityCashOutStake"
          />
          <el-table-column
            :label="$t('common.CashoutReturn')"
            prop="payoutAmount"
          />
          <el-table-column
            :label="$t('common.CompanyWinLoss1')"
            prop="companyWinLoss"
          />
          <el-table-column :label="$t('common.CashoutStatus')" prop="status">
            <template v-slot="{ row }">
              <div v-for="(item, index) in statusList" :key="index">
                <div v-if="row.status == item.id">
                  <p>{{ $t(`common.${item.label}`) }}</p>
                </div>
              </div>
              <div v-if="seriesType != 1">
                <p>{{ row.cancelReason }}</p>
              </div>
              <div v-else>
                <div v-for="item in getInfo(row.remark)" :key="item.id">
                  <p>{{ item.id }}</p>
                  <p>{{ item.info }}</p>
                  <p>{{ formatUtc(item.time) }}</p>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- <a href="https://t.me/keper6">点击</a>
    <a href="skype:live:.cid.81b6bab10dd6bf55?chat">skype</a>
    <a href="https://wa.me/+971553211998" target="_blank">WhatsApp</a>-->
    <div class="order-detail">
      <div class="header">
        <div class="title">
          <i></i>
          <span>{{ $t('common.TransactionDetail') }}</span>
        </div>
        <div class="option"></div>
      </div>
      <div>
        <el-table v-loading="loading" border :data="transactions">
          <el-table-column :label="$t('common.TransactionTime')">
            <template v-slot="{ row }">
              <div v-if="row.createTime">{{ formatUtc(row.createTime) }}</div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('common.TransactionID')" prop="id" />
          <el-table-column :label="$t('common.Type')" prop="type">
            <template v-slot="{ row }">{{ $t(`common.${row.type}`) }}</template>
          </el-table-column>
          <el-table-column :label="$t('common.Amount')" prop="amount" />
          <el-table-column :label="$t('common.Balance')" prop="balance" />
          <el-table-column
            :label="$t('common.TransactionStatus')"
            prop="status"
          >
            <template v-slot="{ row }">
              {{
                row.status == 1 ? $t('common.Successful') : $t('common.Failed')
              }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  const DECIMAL_PLACES = {
    1: { currencyCode: 'RMB' },
    2: { currencyCode: 'USD' },
    3: { currencyCode: 'EUR' },
    4: { currencyCode: 'GBP' },
    5: { currencyCode: 'HKD' },
    6: { currencyCode: 'TWD' },
    7: { currencyCode: 'MYR' },
    8: { currencyCode: 'SGD' },
    9: { currencyCode: 'THB' },
    10: { currencyCode: 'VND' },
    11: { currencyCode: 'KRW' },
    12: { currencyCode: 'JPY' },
    13: { currencyCode: 'PHP' },
    14: { currencyCode: 'IDR' },
    15: { currencyCode: 'INR' },
    16: { currencyCode: 'AUD' },
    17: { currencyCode: 'MMK' },
    18: { currencyCode: 'COP' },
    19: { currencyCode: 'TZS' },
    20: { currencyCode: 'NGN' },
    21: { currencyCode: 'ZMW' },
    22: { currencyCode: 'BRL' },
    23: { currencyCode: 'MXN' },
    24: { currencyCode: 'RUB' },
    25: { currencyCode: 'EGP' },
    26: { currencyCode: 'PEN' },
    27: { currencyCode: 'BOB' },
    28: { currencyCode: 'PKR' },
    29: { currencyCode: 'BDT' },
    30: { currencyCode: 'UGX' },
    31: { currencyCode: 'ETB' },
    32: { currencyCode: 'ZAR' },
    33: { currencyCode: 'HNL' },
    34: { currencyCode: 'CDF' },
    35: { currencyCode: 'TRY' },
    36: { currencyCode: 'IRR' },
    37: { currencyCode: 'GHS' },
    38: { currencyCode: 'KES' },
    39: { currencyCode: 'ILS' },
    40: { currencyCode: 'CLP' },
    41: { currencyCode: 'KZT' },
    42: { currencyCode: 'KGS' },
    43: { currencyCode: 'TJS' },
    44: { currencyCode: 'TMT' },
    45: { currencyCode: 'UZS' },
    46: { currencyCode: 'LAK' },
    47: { currencyCode: 'BND' },
    48: { currencyCode: 'CAD' },
    49: { currencyCode: 'NZD' },
    59: { currencyCode: 'PLN' },
    60: { currencyCode: 'ARS' },
    61: { currencyCode: 'KHR' },
    62: { currencyCode: 'NIO' },
    63: { currencyCode: 'ZWL' },
    64: { currencyCode: 'IMP' },
    65: { currencyCode: 'LKR' },
    66: { currencyCode: 'XOF' },
    67: { currencyCode: 'NPR' },
    68: { currencyCode: 'PGK' },
    69: { currencyCode: 'SAR' },
    70: { currencyCode: 'AED' },
    71: { currencyCode: 'KWD' },
    72: { currencyCode: 'QAR' },
    200: { currencyCode: 'USDT' },
    201: { currencyCode: 'BTC' },
    202: { currencyCode: 'ETH' },
    203: { currencyCode: 'LTC' },
    204: { currencyCode: 'DOGE' },
    205: { currencyCode: 'BCH' },
    206: { currencyCode: 'DASH' },
    207: { currencyCode: 'ETC' },
    208: { currencyCode: 'BUSD' },
    209: { currencyCode: 'USDC' },
    210: { currencyCode: 'XRP' },
    1000: { currencyCode: 'VNDK' },
    1001: { currencyCode: 'IDRK' },
    1003: { currencyCode: 'IRRK' },
    1004: { currencyCode: 'UZSK' },
    1005: { currencyCode: 'LAKK' },
    1006: { currencyCode: 'USDTbc' },
    1007: { currencyCode: 'KRWK' },
    1008: { currencyCode: 'KHRK' },
    1009: { currencyCode: '%TWD' },
    1010: { currencyCode: '÷TWD' },
    1200: { currencyCode: 'mBTC' },
    1201: { currencyCode: 'mETH' },
    1202: { currencyCode: 'mBCH' },
    1203: { currencyCode: 'mDASH' },
    1204: { currencyCode: 'mETC' },
    1205: { currencyCode: 'mLTC' },
  }
  export const SPORT_TYPE_LIST = {
    1: {
      ZHO: '足球',
      VIE: '越南语',
      CMN: '足球',
      SPA: 'fútbol americano',
      JPN: 'フットボール',
      MSA: '马来语',
      HIN: '印地语',
      ENG: 'Soccer',
    },
    2: {
      ZHO: '冰球',
      VIE: 'ViệtName',
      CMN: '冰球',
      SPA: 'disco',
      JPN: 'パック',
      MSA: 'Malay',
      ENG: 'Ice Hockey',
    },
    3: {
      ZHO: '籃球',
      CMN: '篮球',
      SPA: 'baloncesto',
      JPN: 'バスケットボール',
      ENG: 'Basketball',
      KOR: '농구',
    },
    4: {
      ZHO: '橄欖球',
      CMN: '橄榄球',
      SPA: 'fútbol americano',
      JPN: 'フットボール',
      ENG: 'Rugby',
      KOR: '축구',
    },
    5: {
      ZHO: '網球',
      CMN: '网球',
      SPA: 'tenis',
      JPN: 'テニス',
      ENG: 'Tennis',
      KOR: '테니스',
    },
    6: {
      ZHO: '美式足球',
      CMN: '美式足球',
      SPA: 'fútbol americano',
      JPN: 'フットボール',
      ENG: 'American Football',
      KOR: '축구',
    },
    7: {
      ZHO: '棒球',
      CMN: '棒球',
      SPA: 'béisbol',
      JPN: '野球',
      ENG: 'Baseball',
      KOR: '야구',
    },
    8: {
      ZHO: '手球',
      CMN: '手球',
      SPA: 'Balonmano',
      JPN: 'ハンドボール',
      ENG: 'Handball',
      KOR: '핸드볼',
    },
    9: {
      ZHO: '美式棒球（芬蘭）',
      CMN: '美式棒球（芬兰）',
      SPA: 'Béisbol Americano (Finlandia)',
      JPN: 'アメリカの野球（フィンランド）',
      ENG: 'Pesapallo',
    },
    10: {
      ZHO: '地板球',
      CMN: '地板球',
      SPA: 'Bola de piso',
      JPN: 'フロアボール',
      ENG: 'Floorball',
      KOR: '플로어 볼',
    },
    11: {
      ZHO: '曲棍球',
      CMN: '曲棍球',
      SPA: 'hockey',
      JPN: 'ホッケー',
      ENG: 'Bandy',
      KOR: '하키',
    },
    12: {
      ZHO: '高爾夫',
      CMN: '高尔夫',
      SPA: 'Golf',
      JPN: 'ゴルフ',
      ENG: 'Golf',
      KOR: '골프',
    },
    13: {
      ZHO: '排球',
      CMN: '排球',
      SPA: 'vóleibol',
      JPN: 'バレーボール',
      ENG: 'Volleyball',
      KOR: '배구',
    },
    14: {
      ZHO: '板球',
      CMN: '板球',
      SPA: 'Grillo',
      JPN: 'クリケット',
      ENG: 'Cricket',
      KOR: '크리켓',
    },
    15: {
      ZHO: '乒乓球',
      CMN: '乒乓球',
      SPA: 'ping pong',
      JPN: '卓球',
      ENG: 'Table Tennis',
      KOR: '탁구',
    },
    16: {
      ZHO: '斯諾克',
      CMN: '斯诺克',
      SPA: 'Snooker',
      JPN: 'スヌーカー',
      ENG: 'Snooker',
      KOR: '스누커',
    },
    17: {
      ZHO: '五人制足球',
      CMN: '五人制足球',
      SPA: 'Fútbol sala',
      JPN: 'フットサル',
      ENG: 'Futsal',
      KOR: '풋살',
    },
    18: {
      ZHO: '綜合格鬥',
      CMN: '综合格斗',
      SPA: 'Lucha integrada',
      JPN: '統合された戦闘',
      ENG: 'MMA',
    },
    19: {
      ZHO: '拳擊',
      CMN: '拳击',
      SPA: 'boxeo',
      JPN: 'ボクシング',
      ENG: 'Boxing',
      KOR: '권투',
    },
    20: {
      ZHO: '飛鏢',
      CMN: '飞镖',
      SPA: 'Dardos',
      JPN: 'ダーツ',
      ENG: 'Darts',
      KOR: '다트',
    },
    21: {
      ZHO: '超級碗',
      CMN: '超级碗',
      SPA: 'Super Bowl',
      JPN: 'スーパーボール',
      ENG: 'Bowls',
      KOR: '슈퍼 볼',
    },
    22: {
      ZHO: '摩托運動',
      CMN: '摩托运动',
      SPA: 'Deportes de motor',
      JPN: 'モータースポーツ',
      ENG: 'Motor Sports',
    },
    24: {
      ZHO: '水球',
      CMN: '水球',
      SPA: 'Polo acuático',
      JPN: '水球',
      ENG: 'Water Polo',
      KOR: '수구',
    },
    25: {
      ZHO: '自行车',
      CMN: '自行车',
      SPA: 'bicicleta',
      JPN: '自転車',
      ENG: 'Cycling',
      KOR: '자전거',
    },
    26: {
      ZHO: '澳式橄欖球',
      CMN: '澳式橄榄球',
      SPA: 'Rugby australiano',
      JPN: 'オーストラリアのラグビー',
      ENG: 'Aussie Rules',
    },
    27: {
      ZHO: '曲棍球',
      CMN: '曲棍球',
      SPA: 'Hockey',
      JPN: 'ホッケー',
      ENG: 'Hockey',
      KOR: '하키',
    },
    30: {
      ZHO: '高山滑雪',
      CMN: '高山滑雪',
      SPA: 'Esquí alpino',
      JPN: 'アルペンスキー',
      ENG: 'Alpine Skiing',
      KOR: '알파인 스키',
    },
    31: {
      ZHO: '冬季兩項',
      CMN: '冬季两项',
      SPA: 'biatlón',
      JPN: 'バイアスロン',
      ENG: 'Biathlon',
      KOR: '바이애슬론',
    },
    32: {
      ZHO: '冰壺',
      CMN: '冰壶',
      SPA: 'Curling',
      JPN: 'カーリング',
      ENG: 'Curling',
      KOR: '컬링',
    },
    33: {
      ZHO: '北歐兩項',
      CMN: '北欧两项',
      SPA: 'Biatlón nórdico',
      JPN: 'ノルディックバイアスロン',
      ENG: 'Nordic Combined',
    },
    34: {
      ZHO: '跳台滑雪',
      CMN: '跳台滑雪',
      SPA: 'Salto en ski',
      JPN: 'スキージャンプ',
      ENG: 'Ski Jumping',
      KOR: '스키 점프',
    },
    35: {
      ZHO: '越野滑雪',
      CMN: '越野滑雪',
      SPA: 'Esquí de fondo',
      JPN: 'クロスカントリースキー',
      ENG: 'Cross-Country Skiing',
    },
    36: {
      ZHO: '双人雪橇',
      CMN: '双人雪橇',
      SPA: 'Trineo doble',
      JPN: '二重そり',
      ENG: 'Bobsleigh',
      KOR: '더블 썰매',
    },
    37: {
      ZHO: '自由式滑雪',
      CMN: '自由式滑雪',
      SPA: 'Esquí de estilo libre',
      JPN: 'フリースタイル・スキー',
      ENG: 'Freestyle Skiing',
    },
    38: {
      ZHO: '單雪橇',
      CMN: '单雪橇',
      SPA: 'Trineo sencillo',
      JPN: 'シングルスレッド',
      ENG: 'Luge',
      KOR: '단일 썰매',
    },
    39: {
      ZHO: '短道速滑',
      CMN: '短道速滑',
      SPA: 'Patinaje de velocidad sobre pista corta',
      JPN: 'ショートトラックスピードスケート',
    },
    40: {
      ZHO: '鋼架雪車',
      CMN: '钢架雪车',
      SPA: 'Moto de nieve con marco de acero',
      JPN: '鉄骨スノーモービル',
      ENG: 'Skeleton',
    },
    41: {
      ZHO: '單板滑雪',
      CMN: '单板滑雪',
      SPA: 'Tabla de snowboard',
      JPN: 'スノーボード',
      ENG: 'Snowboard',
      KOR: '스노우 보드',
    },
    42: {
      ZHO: '速度滑冰',
      CMN: '速度滑冰',
      SPA: 'Patinaje de velocidad',
      JPN: 'スピードスケート',
      ENG: 'Speed Skating',
    },
    43: {
      ZHO: '花樣滑冰',
      CMN: '花样滑冰',
      SPA: 'Patinaje artístico',
      JPN: 'フィギュアスケート',
      ENG: 'Figure Skating',
    },
    45: {
      ZHO: '滾軸曲棍球',
      CMN: '滚轴曲棍球',
      SPA: 'Hockey sobre patines',
      JPN: 'ローラーホッケー',
      ENG: 'Roller Hockey',
    },
    46: {
      ZHO: '沙灘足球',
      CMN: '沙滩足球',
      SPA: 'futbol playa',
      JPN: 'ビーチサッカー',
      ENG: 'Beach Soccer',
      KOR: '해변 축구',
    },
    47: {
      ZHO: '羽毛球',
      CMN: '羽毛球',
      SPA: 'bádminton',
      JPN: 'バドミントン',
      ENG: 'Badminton',
      KOR: '배드민턴',
    },
    48: {
      ZHO: '霹雳舞',
      CMN: '霹雳舞',
      ENG: 'Breakdancing',
    },
    49: {
      ZHO: '射箭',
      CMN: '射箭',
      SPA: 'Tiro al arco',
      JPN: 'アーチェリー',
      ENG: 'Archery',
      KOR: '양궁',
    },
    50: {
      ZHO: '田徑運動',
      CMN: '田径运动',
      SPA: 'Eventos de campo',
      JPN: 'フィールドイベント',
      ENG: 'Athletics',
      KOR: '야외 활동',
    },
    51: {
      ZHO: '沙灘排球',
      CMN: '沙滩排球',
      SPA: 'Voleibol de playa',
      JPN: 'ビーチバレー',
      ENG: 'Beach Volleyball',
    },
    55: {
      ZHO: '潛水',
      CMN: '潜水',
      SPA: 'Buceo',
      JPN: 'ダイビング',
      ENG: 'Diving',
      KOR: '다이빙',
    },
    56: {
      ZHO: '馬術',
      CMN: '马术',
      SPA: 'Ecuestre',
      JPN: '馬術',
      ENG: 'Equestrian',
      KOR: '승마자',
    },
    57: {
      ZHO: '擊劍',
      CMN: '击剑',
      SPA: 'Esgrima',
      JPN: 'フェンシング',
      ENG: 'Fencing',
      KOR: '펜싱',
    },
    58: {
      ZHO: '柔道',
      CMN: '柔道',
      SPA: 'judo',
      JPN: '柔道',
      ENG: 'Judo',
      KOR: '유도',
    },
    59: {
      ZHO: '现代五项',
      CMN: '現代五項',
      SPA: 'Pentatlón moderno',
      JPN: '近代五種',
      ENG: 'Modern Pentathlon',
    },
    61: {
      ZHO: '皮划艇',
      CMN: '皮划艇',
      SPA: 'Kayak',
      JPN: 'カヤック',
      ENG: 'Canoe',
      KOR: '카약',
    },
    62: {
      ZHO: '體操',
      CMN: '体操',
      SPA: 'gimnasia',
      JPN: '体操',
      ENG: 'Gymnastics',
      KOR: '체조',
    },
    63: {
      ZHO: '静水竞速',
      CMN: '静水竞速',
      ENG: 'Canoe Sprint',
    },
    64: {
      ZHO: '激流回旋',
      CMN: '激流回旋',
      ENG: 'Canoe Sprint',
    },
    65: {
      ZHO: '空手道',
      CMN: '空手道',
      ENG: 'Karate',
    },
    66: {
      ZHO: '划船',
      CMN: '划船',
      SPA: 'Remo',
      JPN: 'ローイング',
      ENG: 'Rowing',
      KOR: '로잉',
    },
    67: {
      ZHO: '射擊',
      CMN: '射击',
      SPA: 'disparo',
      JPN: '撮影',
      ENG: 'Shooting',
      KOR: '촬영',
    },
    68: {
      ZHO: '雙人花樣游泳',
      CMN: '双人花样游泳',
      SPA: 'Natación sincronizada doble',
      JPN: 'ダブルシンクロナイズドスイミング',
    },
    69: {
      ZHO: '游泳',
      CMN: '游泳',
      SPA: 'Nadando',
      JPN: '水泳',
      ENG: 'Swimming',
      KOR: '수영',
    },
    70: {
      ZHO: '跆拳道',
      CMN: '跆拳道',
      SPA: 'Taekwondo',
      JPN: 'テコンドー',
      ENG: 'Taekwondo',
      KOR: '태권도',
    },
    71: {
      ZHO: '蹦床',
      CMN: '蹦床',
      SPA: 'Trampolín',
      JPN: 'トランポリン',
      ENG: 'Trampoline',
      KOR: '트램폴린',
    },
    72: {
      ZHO: '鐵人三項',
      CMN: '铁人三项',
      SPA: 'Triatlón',
      JPN: 'トライアスロン',
      ENG: 'Triathlon',
      KOR: '트라이 애슬론',
    },
    73: {
      ZHO: '舉重',
      CMN: '举重',
      SPA: 'levantamiento de pesas',
      JPN: '重量挙げ',
      ENG: 'Weightlifting',
      KOR: '역도',
    },
    74: {
      ZHO: '摔跤',
      CMN: '摔跤',
      SPA: 'Lucha',
      JPN: 'レスリング',
      ENG: 'Wrestling',
      KOR: '레슬링',
    },
    75: {
      ZHO: '帆船運動',
      CMN: '帆船运动',
      SPA: 'Navegación',
      JPN: 'セーリング',
      ENG: 'Sailing',
      KOR: '항해',
    },
    76: {
      ZHO: '賽馬',
      CMN: '赛马',
      SPA: 'Las carreras de caballos',
      JPN: '競馬',
      ENG: 'Horse Racing',
      KOR: '경마',
    },
    77: {
      ZHO: '盖尔式曲棍球',
      CMN: '盖尔式曲棍球',
      SPA: 'Hockey gaélico',
      JPN: 'ゲーリックホッケー',
      ENG: 'Gaelic Hurling',
    },
    79: {
      ZHO: '壁球',
      CMN: '壁球',
      SPA: 'squash',
      JPN: '押しつぶす',
      ENG: 'Squash',
      KOR: '스쿼시',
    },
    80: {
      ZHO: '國際象棋',
      CMN: '国际象棋',
      SPA: 'Ajedrez internacional',
      JPN: '国際チェス',
      ENG: 'Chess',
      KOR: '국제 체스',
    },
    81: {
      ZHO: '賽狗',
      CMN: '赛狗',
      SPA: 'Galgo',
      JPN: 'グレイハウンド',
      ENG: 'Greyhound Racing',
      KOR: '그레이하운드',
    },
    82: {
      ZHO: '輕駕車賽馬',
      CMN: '轻驾车赛马',
      SPA: 'Arnés de carreras',
      JPN: 'ハーネスレース',
      ENG: 'Harness Racing',
    },
    83: {
      ZHO: '電子競技',
      CMN: '电子竞技',
      SPA: 'ESports',
      JPN: 'ESports',
      ENG: 'E-sports',
      KOR: '에스 포트',
    },
    86: {
      ZHO: '霍爾曲棍球',
      CMN: '霍尔曲棍球',
      SPA: 'Hockey Hall',
      JPN: 'ホールホッケー',
      ENG: 'Hall Hockey',
      KOR: '홀 하키',
    },
    87: {
      ZHO: '沙灘手球',
      CMN: '沙滩手球',
      SPA: 'Balonmano playa',
      JPN: 'ビーチハンドボール',
      ENG: 'Beach Handball',
    },
    88: {
      ZHO: '衝浪',
      CMN: '冲浪',
      SPA: 'navegar',
      JPN: 'サーフィン',
      ENG: 'Surfing',
      KOR: '서핑',
    },
    89: {
      ZHO: '相撲',
      CMN: '相扑',
      SPA: 'Sumo',
      JPN: '相撲',
      ENG: 'Sumo',
      KOR: '스모',
    },
    90: {
      ZHO: '無擋板籃球',
      CMN: '无挡板篮球',
      SPA: 'Baloncesto',
      JPN: 'ネットボール',
      ENG: 'Netball',
      KOR: '네트볼',
    },
    91: {
      ZHO: '長曲棍球',
      CMN: '长曲棍球',
      SPA: 'Lacrosse',
      JPN: 'ラクロス',
      ENG: 'Lacrosse',
      KOR: '라크로스',
    },
    92: { ZHO: 'F1赛车', CMN: 'F1赛车', ENG: 'Formula 1' },
    93: { ZHO: '特别项目', CMN: '特别项目', ENG: 'Specials' },
    94: { ZHO: '赛车', CMN: '赛车', ENG: 'Stock Car Racing' },
    95: { ZHO: '摩托车', CMN: '摩托车', ENG: 'Motorcycle Racing' },
    100: { ZHO: '奥林匹克', CMN: '奥林匹克', ENG: 'Olympics' },
    101: { ZHO: '亞運會', CMN: '亚运会', ENG: 'Asian Game' },
    164: {
      ZHO: '刀塔 2',
      CMN: '刀塔2',
      SPA: 'Dutta',
      JPN: 'Dota 2',
      ENG: 'Dota2',
      KOR: '도타 2',
    },
    165: {
      ZHO: '英雄聯盟',
      CMN: '英雄联盟',
      SPA: 'Liga de Leyendas',
      JPN: 'リーグ・オブ・レジェンド',
      ENG: 'League of Legends',
    },
    177: {
      ZHO: '電競足球',
      CMN: '电竞足球',
      SPA: 'Fútbol e-sports',
      JPN: 'Eスポーツサッカー',
      ENG: 'E-Soccer',
      KOR: '전자 스포츠 풋볼',
    },
    178: {
      ZHO: '電競籃球',
      CMN: '电竞篮球',
      SPA: 'Baloncesto e-sports',
      JPN: 'Eスポーツバスケットボール',
      ENG: 'E-Basketball',
    },
    179: {
      ZHO: '反恐精英：全球攻勢',
      CMN: '反恐精英：全球攻势',
      SPA: 'Counter Strike ofensiva global',
      JPN: 'カウンターストライク：グローバル攻勢',
      ENG: 'CS:GO',
    },
    180: {
      ZHO: '王者榮耀',
      CMN: '王者荣耀',
      SPA: 'Rey gloria',
      JPN: 'キンググローリー',
      ENG: 'Glory of Kings',
      KOR: '왕의 영광',
    },
    1001: {
      ZHO: '虛擬足球',
      CMN: '虚拟足球',
      SPA: 'Fútbol Virtual',
      JPN: 'バーチャルサッカー',
      ENG: 'Virtual Soccer',
      KOR: '가상 축구',
    },
    1002: {
      ZHO: '虛擬籃球',
      CMN: '虚拟篮球',
      SPA: 'Baloncesto Virtual',
      JPN: 'バーチャルバスケットボール',
      ENG: 'Virtual Basketball',
      KOR: '가상 농구',
    },
    1020: {
      ZHO: '虛擬賽馬',
      CMN: '虚拟赛马',
      SPA: 'Carreras de Caballos Virtual',
      JPN: 'バーチャル競馬',
      ENG: 'Virtual Horse',
      KOR: '가상 경마',
    },
    1021: {
      ZHO: '虛擬賽狗',
      CMN: '虚拟赛狗',
      SPA: 'Carreras de Galgos Virtual',
      JPN: 'バーチャルグレイハウンド',
      ENG: 'Virtual Greyhounds',
      KOR: '가상 그레이하운드',
    },
    1022: {
      ZHO: '虛擬泥地摩托車',
      CMN: '虚拟泥地摩托车',
      SPA: 'Carreras de Speedway Virtual',
      JPN: 'バーチャルスピードウェイ',
      ENG: 'Virtual Speedway',
      KOR: '가상 스피드웨이',
    },
    1023: {
      ZHO: '虛擬摩托車',
      CMN: '虚拟摩托车',
      SPA: 'Carreras de Motos Virtual',
      JPN: 'バーチャルモーターバイク',
      ENG: 'Virtual Motorbik',
      KOR: '가상 모터사이클',
    },
  }
  export default {
    name: 'Home',
    data() {
      return {
        languageList: [
          { name: 'English', val: 'ENG' },
          { name: '中文简体', val: 'CMN' },
        ],
        timeZone: [
          { name: 'UTC +12', val: '12' },
          { name: 'UTC +11', val: '11' },
          { name: 'UTC +10', val: '10' },
          { name: 'UTC +9', val: '9' },
          { name: 'UTC +8', val: '8' },
          { name: 'UTC +7', val: '7' },
          { name: 'UTC +6', val: '6' },
          { name: 'UTC +5', val: '5' },
          { name: 'UTC +4', val: '4' },
          { name: 'UTC +3', val: '3' },
          { name: 'UTC +2', val: '2' },
          { name: 'UTC +1', val: '1' },
          { name: 'UTC +0', val: '0' },
          { name: 'UTC -1', val: '-1' },
          { name: 'UTC -2', val: '-2' },
          { name: 'UTC -3', val: '-3' },
          { name: 'UTC -4', val: '-4' },
          { name: 'UTC -5', val: '-5' },
          { name: 'UTC -6', val: '-6' },
          { name: 'UTC -7', val: '-7' },
          { name: 'UTC -8', val: '-8' },
          { name: 'UTC -9', val: '-9' },
          { name: 'UTC -10', val: '-10' },
          { name: 'UTC -11', val: '-11' },
        ],
        timer: '',
        language: '',
        queryData: {
          id: '',
          expiration: '',
          sign: '',
          languageType: '',
          merchantId: '',
        },
        loading: false,
        statusList: [
          {
            id: 0,
            label: 'Created',
          },
          {
            id: 1,
            label: 'Confirming',
          },
          {
            id: 2,
            label: 'Rejected',
          },
          {
            id: 3,
            label: 'Canceled',
          },
          {
            id: 4,
            label: 'Confirmed',
          },
          {
            id: 5,
            label: 'Settled',
          },
        ],
        api: '',
        orderId: '',
        fBUserID: '',
        channelUserID: '',
        status: '',
        type: '',
        currency: '',
        stake: '',
        companyWinLoss: '',
        ip: '',
        orderTime: '',
        settleTime: '',
        list: [],
        cashOutCount: '',
        cashOutReturn: '',
        cashOutStake: '',
        liabilityCashoutStake: '',
        settleReturn: '',
        liabilityStake: '',
        settleStake: '',
        seriesType: '',
        allUpBetRemark: '',
        maxWinAmount: '',
        betNum: '',
        betType: '',
        transactions: [],
        cashOuts: [],
      }
    },
    computed: {
      getCode() {
        return DECIMAL_PLACES[this.currency || 1].currencyCode || 'RMB'
      },
    },
    mounted() {
      this.offset = new Date().getTimezoneOffset()
      const timeOffset = this.$moment().utcOffset() / 60 // 获取获取UTC偏移量(时差)
      this.timer = timeOffset
      const { id, expiration, sign, api, merchantId } = this.$route.query
      if (!id || !expiration || !sign || !api || !merchantId) {
        return
      }
      this.queryData.id = id
      this.queryData.expiration = expiration
      this.queryData.sign = sign
      this.api = api
      this.queryData.merchantId = merchantId
      //this.language = languageType
      this.fetchData()
    },
    created() {
      const { languageType } = this.$route.query
      let localType = localStorage.getItem('type')
      if (localType == 1) {
        this.language = localStorage.getItem('language')
      } else {
        if (languageType && ['CMN', 'ENG'].includes(languageType)) {
          this.language = languageType
          if (
            languageType &&
            languageType !== localStorage.getItem('language')
          ) {
            location.reload()
          }
          localStorage.setItem('language', languageType)
        } else {
          this.language = 'CMN'
          localStorage.setItem('language', 'CMN')
        }
      }

      localStorage.setItem('type', 1)
    },
    methods: {
      getInfo(str) {
        if (!str) {
          return
        }
        let strArr = str.split(',')
        const data = strArr.map((item) => {
          if (item.indexOf(':') > 0) {
            let keyValue = item.split(':')
            let key = ''
            let value = ''
            if (keyValue.length > 2) {
              key = keyValue.slice(1, keyValue.length - 1).join('')
              value = keyValue[keyValue.length - 1]
            } else {
              key = keyValue[0]
              value = keyValue[1]
            }
            let id = null
            let time = null
            if (value.indexOf('-') > 0) {
              let IdTime = value.split('-')
              id = IdTime[0]
              time = IdTime[1]
            }
            return {
              info: key.replace(/\(.*\)/g, ''),
              id,
              time,
            }
          } else {
            return {
              info: item.replace(/\(.*\)/g, ''),
              id: null,
              time: null,
            }
          }
        })
        return data
      },
      fetchData() {
        this.queryData.languageType = this.language
        this.loading = true
        axios
          .get(
            this.api +
              '/merchant/order/detail?' +
              `id=${this.queryData.id}` +
              `&expiration=${this.queryData.expiration}` +
              `&merchantId=${this.queryData.merchantId}` +
              `&sign=${this.queryData.sign}` +
              `&languageType=${this.queryData.languageType}`
          )
          .then((res) => {
            this.loading = false
            if (res.data.success) {
              this.orderId = res.data.data.id
              this.fBUserID = res.data.data.userId
              this.channelUserID = res.data.data.channelUserId
              this.status = res.data.data.status
              this.type = res.data.data.seriesType
              this.currency = res.data.data.currencyId
              this.stake = res.data.data.stakeAmount
              this.companyWinLoss = res.data.data.companyWinLoss
              this.ip = res.data.data.ip
              this.orderTime = res.data.data.createTime || ''
              this.cashOutCount = res.data.data.cashOutCount
              this.settleTime = res.data.data.settleTime || ''
              this.seriesType = res.data.data.seriesType
              this.cashOutReturn = res.data.data.cashOutReturn
              this.cashOutStake = res.data.data.cashOutStake
              this.liabilityCashoutStake = res.data.data.liabilityCashoutStake
              this.settleReturn = res.data.data.settleReturn
              this.betType = res.data.data.betType
              this.settleStake = res.data.data.settleStake
              this.allUpBetRemark = res.data.data.allUpBetRemark
              this.betNum = res.data.data.betNum
              this.maxWinAmount = res.data.data.maxWinAmount
              this.liabilityStake = res.data.data.liabilityStake
              this.list = [res.data.data]
              this.transactions = res.data.data.transactions || []
              this.cashOuts = res.data.data.cashOuts || []
            } else {
              this.open(res.data.message)
            }
          })
      },
      open(val) {
        const h = this.$createElement
        this.$msgbox({
          title: '提示',
          message: h('p', null, [
            h('span', null),
            h('span', { style: 'color: teal' }, `${val}`),
          ]),
          //showCancelButton: true,
          confirmButtonText: '取消',
          //cancelButtonText: '取消',
        }).then(() => {
          //this.fetchData()
        })
      },
      getSportName(val) {
        console.log(val,'----getSportName')
        if (this.language == 'CMN') {
          return SPORT_TYPE_LIST[val || 1].CMN
        } else {
          return SPORT_TYPE_LIST[val || 1].ENG
        }
      },
      handleSetLanguage(lang) {
        this.$i18n.locale = lang
        localStorage.setItem('language', lang)
        location.reload()
      },
      changeTimeZone(val) {
        this.timer = val
      },
      odds(stakeAmount, maxWinAmount, betNum) {
        return (
          (Number(stakeAmount) + Number(maxWinAmount)) /
          (Number(stakeAmount) / Number(betNum))
        ).toFixed(2)
      },
      // utc时间
      formatUtc(time) {
        return this.$moment(+time)
          .utcOffset(this.timer)
          .format('YYYY-MM-DD HH:mm:ss')
      },
    },
  }
</script>

<style>
  body {
    padding: 0;
    margin: 0;
  }
  p {
    margin: 0;
  }
  .content {
    padding: 32px 20px;
  }
  .order-detail {
    margin-bottom: 32px;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .right-option {
    display: flex;
    align-items: center;
  }
  .title {
    position: relative;
  }
  .title i {
    position: absolute;
    height: 23px;
    width: 5px;
    background: #21497b;
    border-radius: 0 10px 10px 0;
    left: 0;
    top: 8px;
  }
  .title span {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    color: #000000;
    margin-left: 10px;
  }
  .count {
    font-weight: 600;
    font-size: 16px;
    color: #1e2542;
    height: 35px;
    line-height: 35px;
    padding: 0 20px;
    border: 1px solid #e6e6e6;
    border-radius: 50px;
    display: inline-block;
    margin: 0 0 20px;
  }
  .option span {
    font-weight: 600;
    font-size: 14px;
    color: #333;
  }
  .card {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .card div {
    width: 19.4%;
    margin-bottom: 10px;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    height: 86px;
  }
  .card div p {
    margin: 0;
    padding: 0 10px;
    height: 38px;
    line-height: 38px;
    background: #dee5ee;
    color: #1e2542;
    font-weight: 600;
    font-size: 14px;
    border-radius: 8px 8px 0 0;
  }
  .card div .label {
    margin: 0;
    display: inline-block;
    padding: 0 10px;
    height: 48px;
    line-height: 48px;
    font-weight: normal;
    font-size: 16px;
    color: #333333;
  }
  .el-table {
    border-radius: 10px;
  }
  .el-table thead th {
    background: #dee5ee !important;
    font-weight: 600;
    font-size: 14px;
    color: #000;
  }
  .el-table .el-table__cell {
    padding: 6px 0 !important;
  }
  .result-text {
    display: flex;
    align-items: center;
  }
  .result-text span {
    margin-right: 2px;
  }
  .table-td-item {
    width: calc(100% + 20px);
    padding: 10px;
    border-bottom: 1px solid #ebeef5;
    margin: 0 0 0 -10px;
    min-height: 110px;
  }
  .table-td-item:last-child {
    border-bottom: 1px solid transparent;
  }
  .bet-detail .el-table tbody tr td {
    padding: 0 !important;
  }
  .text-red {
    color: #e1554f;
  }
  .win {
    color: #e1554f !important;
  }
  .loss {
    color: #00a791 !important;
  }
  .result {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
  .el-message-box__message {
    text-align: center;
  }
  .text-red {
    color: #e1554f;
  }
  .text-blue {
    color: #409eff;
  }
  .text-gray {
    color: #999;
  }
  .text-green {
    color: #00a791;
  }
</style>
